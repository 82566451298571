import React from 'react'
import { qualificationType } from '../shared/data-leads'
import { Container } from 'reactstrap'
export type ThankyouProps = {
    formData: qualificationType
}
export function Thankyou(props: ThankyouProps) {
    return (
        <Container>
            <p>Thank you for submitting our survey. One of the Attain Loans team will be in touch to set up an appointment, where we'll get to know you and your financial situation and discuss your lending needs.</p>
            <p>For further information, visit us on:</p>
            <p>W. <a href="https://attainloans.com.au/">https://attainloans.com.au/</a></p>
            <p>E. <a href="mailto:info@attainloans.com.au">info@attainloans.com.au</a></p>
        </Container>
    )
}