import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';
import { SecurityData } from './shared/SecurityData';
const firebaseConfig = {
  apiKey: "AIzaSyAEGsqv3B9icYIr2pCVUDKQJmrzDxAkD8E",
  authDomain: "mystores-andmore.firebaseapp.com",
  databaseURL: "https://attain-loans.firebaseio.com/",
  projectId: "mystores-andmore",
  storageBucket: "mystores-andmore.appspot.com",
  messagingSenderId: "780246406443",
  appId: "1:780246406443:web:333da3f1ceaf653304616e",
  measurementId: "G-D8GRK5WW1L"
};

const runBootstrap = async () => {
  const firebaseApp = firebase.initializeApp(firebaseConfig)
  let called = false;
  const unSubscribe = firebaseApp.auth().onAuthStateChanged((user: SecurityData.FirabaseAuthUser | any) => {
    if (called) {
      return;
    }
    if (user) {
      console.log(user)
      called = true;
      console.log("USER", user)
      ReactDOM.render(<App uid={user.uid} firebaseApp={firebaseApp}  />, document.getElementById('root'));
      unSubscribe();
    } else {
      // console.log("masuk tidak ada user")
      console.error('user null. berarti login di sini');
      firebaseApp.auth().signInAnonymously()
        .then(d => {
          console.log('User data: ', d);
        }).catch(exc => {
          console.error('Gagal login, error: ', exc);
        });
    }
  });
}
runBootstrap()

serviceWorker.unregister();
